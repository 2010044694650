<template>
  <div v-if="debtsData" class="table-container">
    <table class="my-table">
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th colspan="3">{{ $t("students.data") }}</th>
          <th :colspan="currentTabIsAll ? 4 : 3">{{ $t("Debts.data") }}</th>
          <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
        </tr>
        <tr>
          <th>{{ $t("general.image") }}</th>
          <th>{{ $t("general.code") }}</th>
          <th class="cell-lg">{{ $t("general.name") }}</th>

          <th>{{ $t("general.value") }}</th>
          <th>{{ $t("general.paid") }}</th>
          <th>{{ $t("general.remainding") }}</th>
          <th v-if="currentTabIsAll">{{ $t("Debts.schedulingStatusType") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(debt, index) in debtsData" :key="index">
          <td>{{ ++index + filterData.currentIndex }}</td>
          <td>
            <img
              class="item-img-table"
              :src="
                fullPathFileFromServer(
                  debt.userInfoData.userImagePath,
                  defaultImg
                )
              "
              :onerror="`this.src='${defaultImg}'`"
            />
          </td>
          <td>{{ isDataExist(debt.userInfoData.fullCode) }}</td>
          <td>
            {{ isDataExist(debt.userInfoData.userNameCurrent) }}
          </td>

          <td>
            {{ isDataExist(debt.debtMoneyStatistics.debtMoneyWithCurrency) }}
          </td>
          <td>
            {{ isDataExist(debt.debtMoneyStatistics.paidMoneyWithCurrency) }}
          </td>
          <td>
            {{
              isDataExist(debt.debtMoneyStatistics.remainderMoneyWithCurrency)
            }}
          </td>
          <td v-if="currentTabIsAll">
            {{
              isDataExist(debt.schedulingData.schedulingStatusTypeNameCurrent)
            }}
          </td>

          <td class="fmenu-item-container">
            <FloatingMenu>
              <li>
                <button
                  @click="
                    setDebtData(debt);
                    openBottomSheet('DebtInfo');
                  "
                  :title="$t('info')"
                >
                  <img src="@/assets/images/info.svg" />
                </button>
              </li>
              <li
                v-if="
                  checkPrivilege(hasInstallmentScheduleTime()) &&
                  (currentTab == 'notScheduled' || currentTab == 'scheduled')
                "
              >
                <router-link
                  :to="{
                    name: 'InstallmentScheduleTimes',
                    params: {
                      userToken: ' ',
                      debtToken: debt.debtToken,
                    },
                  }"
                  target="_blank"
                  :title="$t('Installments.modelName')"
                >
                  <img src="@/assets/images/installments.svg" />
                </router-link>
              </li>
              <li
                v-if="
                  checkPrivilege(hasDebtCancelInstallment()) &&
                  currentTab == 'scheduled'
                "
              >
                <button
                  @click="setDebtDataToCancel(debt)"
                  :title="$t('Installments.cancel')"
                >
                  <img src="@/assets/images/cancel.svg" />
                </button>
              </li>
              <li>
                <button
                  @click="
                    setDebtData(debt);
                    openBottomSheet('ActionsData');
                  "
                  :title="$t('actionsData.modelName')"
                >
                  <img src="@/assets/images/actions-data.svg" />
                </button>
              </li>
            </FloatingMenu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  isDataExist,
  checkPrivilege,
  fullPathFileFromServer,
} from "./../../../../utils/functions";
import FloatingMenu from "./../../../../components/general/FloatingMenu.vue";
import generalMixin from "./../../../../utils/generalMixin";
import {
  hasInstallmentScheduleTime,
  hasDebtCancelInstallment,
} from "./../../../../utils/privilegeHelper";

export default {
  name: "DebtsTable",
  mixins: [generalMixin],
  components: {
    FloatingMenu,
  },
  props: ["debtsData", "filterData", "defaultImg", "currentTab"],
  computed: {
    currentTabIsAll() {
      return this.currentTab == "all";
    },
  },
  methods: {
    setDebtData(debt) {
      this.$emit("setDebtData", debt);
    },
    setDebtDataToCancel(debt) {
      if (debt.debtMoneyStatistics.paidMoney == 0) {
        this.$emit("setDebtData", debt);
        this.$bvModal.show("CancelDebtInstallments");
      } else {
        this.showMsg(this.$t("Installments.cancelNotvalid"));
      }
    },
    isDataExist,
    checkPrivilege,
    fullPathFileFromServer,
    hasInstallmentScheduleTime,
    hasDebtCancelInstallment,
  },
};
</script>
