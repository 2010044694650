<template>
  <b-tabs v-if="theOptions" content-class="">
    <b-tab
      v-for="(option, index) in theOptions"
      :key="index"
      @click="
        filter(option.itemToken, option.filterName);
        currentTab = option.currentTab;
      "
      :title-item-class="'custom-tab-outer-li'"
      :active="currentTab == option.currentTab ? true : false"
    >
      <template #title>
        <span v-b-popover.hover.bottomright="`${option.itemName}`">
          <img :src="`${option.itemImagePath}`" class="icon-tab" />
          <span>{{ option.itemName }}</span>
          <span class="badge badge-dark" style="margin-inline-start: 8px">
            {{ option.count }}
          </span>
        </span>
      </template>
    </b-tab>
  </b-tabs>
</template>

<script>
import imgAll from "@/assets/images/debts.svg";
import imgNotScheduled from "@/assets/images/approval.svg";
import imgScheduled from "@/assets/images/installments.svg";
import imgCanceled from "@/assets/images/cancel.svg";
import imgFinished from "@/assets/images/finish.svg";

import {
  SCHEDULING_STATUS_TOKENS,
  CANCEL_TYPE_TOKENS,
} from "./../../../../utils/constantLists";

export default {
  name: "DebtTabs",
  props: {
    debtStatistics: {
      type: Object,
    },
    theFilterData: {
      type: Object,
    },
  },
  data() {
    return {
      filterNameScheduling: "schedulingStatusTypeToken",
      filterNameCancel: "cancelTypeToken",
      currentTab: "",
      filterData: this.theFilterData,
    };
  },
  computed: {
    countNotScheduled() {
      return this.debtStatistics.countDebtsNotHaveSchedul;
    },
    countScheduled() {
      return this.debtStatistics.countDebtsIsHaveSchedul;
    },
    countCanceled() {
      return this.debtStatistics.countDebtsCanceled;
    },
    countFinished() {
      return this.debtStatistics.countDebtsFinish;
    },
    allCount() {
      return this.debtStatistics.totalCount;
    },

    theOptions() {
      let tabOptions = [];

      tabOptions.push({
        itemToken: SCHEDULING_STATUS_TOKENS.NotHaveSchedule,
        filterName: this.filterNameScheduling,
        itemName: this.$t("Debts.tabs.notScheduled"),
        itemImagePath: imgNotScheduled,
        currentTab: "notScheduled",
        count: this.countNotScheduled,
      });
      tabOptions.push({
        itemToken: SCHEDULING_STATUS_TOKENS.IsHaveSchedule,
        filterName: this.filterNameScheduling,
        itemName: this.$t("Debts.tabs.scheduled"),
        itemImagePath: imgScheduled,
        currentTab: "scheduled",
        count: this.countScheduled,
      });
      tabOptions.push({
        itemToken: CANCEL_TYPE_TOKENS.Canceled,
        filterName: this.filterNameCancel,
        itemName: this.$t("Debts.tabs.canceled"),
        itemImagePath: imgCanceled,
        currentTab: "canceled",
        count: this.countCanceled,
      });
      tabOptions.push({
        itemToken: CANCEL_TYPE_TOKENS.NotCanceled,
        filterName: this.filterNameCancel,
        itemName: this.$t("Debts.tabs.finshed"),
        itemImagePath: imgFinished,
        currentTab: "finshed ",
        count: this.countFinished,
      });

      tabOptions.push({
        itemToken: "",
        filterName: "",
        itemName: this.$t("Debts.tabs.all"),
        itemImagePath: imgAll,
        currentTab: "all",
        count: this.allCount,
      });

      return tabOptions;
    },
  },
  watch: {
    currentTab() {
      this.currentTabUpdated();
    },
  },
  async created() {
    this.setCurrentTab();
  },
  methods: {
    setCurrentTab() {
      this.currentTab = "notScheduled";
      this.filter(
        SCHEDULING_STATUS_TOKENS.NotHaveSchedule,
        this.filterNameScheduling
      );
    },
    filter(filterToken, filterName) {
      if (filterToken == CANCEL_TYPE_TOKENS.NotCanceled) {
        this.filterData.isFinshDebt = true;
        this.filterData.isNotFinshDebt = false;
      } else {
        this.filterData.isFinshDebt = false;
        this.filterData.isNotFinshDebt = true;
      }

      if (filterName) {
        if (this.filterData[filterName] != filterToken) {
          this.filterData[filterName] = filterToken;
        }
        switch (filterName) {
          case this.filterNameScheduling:
            this.filterData[this.filterNameCancel] = "";
            if (filterToken == SCHEDULING_STATUS_TOKENS.IsHaveSchedule) {
              this.filterData[this.filterNameCancel] =
                CANCEL_TYPE_TOKENS.NotCanceled;
            }
            break;
          case this.filterNameCancel:
            this.filterData[this.filterNameScheduling] = "";
            break;
        }
      } else {
        //all
        this.filterData[this.filterNameScheduling] = "";
        this.filterData[this.filterNameCancel] = "";
        this.filterData.isFinshDebt = false;
        this.filterData.isNotFinshDebt = false;
      }

      this.$emit("search", this.filterData);
    },
    currentTabUpdated() {
      this.$emit("currentTabUpdated", this.currentTab);
    },
  },
};
</script>
