<template>
  <div class="row custom-cards">
    <CustomCard
      v-for="(debt, index) in debtsData"
      :key="index"
      :className="'col-sm-6 col-md-4 col-lg-3'"
      :title="debt.debtTitleCurrent"
      :description="''"
      :imagePath="defaultImg"
      :defaultImg="defaultImg"
      :index="++index + filterData.currentIndex"
    >
      <li>
        <button
          :title="$t('info')"
          @click="
            setDebtData(debt);
            openBottomSheet('DebtInfo');
          "
        >
          <img src="@/assets/images/info.svg" />
        </button>
      </li>
      <li
        v-if="
          checkPrivilege(hasInstallmentScheduleTime()) &&
          (currentTab == 'notScheduled' || currentTab == 'scheduled')
        "
      >
        <router-link
          :to="{
            name: 'InstallmentScheduleTimes',
            params: {
              userToken: ' ',
              debtToken: debt.debtToken,
            },
          }"
          target="_blank"
          :title="$t('Installments.modelName')"
        >
          <img src="@/assets/images/installments.svg" />
        </router-link>
      </li>
      <li
        v-if="
          checkPrivilege(hasDebtCancelInstallment()) &&
          currentTab == 'scheduled'
        "
      >
        <button
          @click="setDebtDataToCancel(debt)"
          :title="$t('Installments.cancel')"
        >
          <img src="@/assets/images/cancel.svg" />
        </button>
      </li>

      <li>
        <button
          @click="
            setDebtData(debt);
            openBottomSheet('ActionsData');
          "
          :title="$t('actionsData.modelName')"
        >
          <img src="@/assets/images/actions-data.svg" />
        </button>
      </li>
    </CustomCard>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
} from "./../../../../utils/functions";
import CustomCard from "./../../../../components/general/CustomCard.vue";
import generalMixin from "./../../../../utils/generalMixin";
import {
  hasInstallmentScheduleTime,
  hasDebtCancelInstallment,
} from "./../../../../utils/privilegeHelper";

export default {
  mixins: [generalMixin],
  components: {
    CustomCard,
  },
  props: ["debtsData", "filterData", "defaultImg", "currentTab"],
  methods: {
    setDebtData(debt) {
      this.$emit("setDebtData", debt);
    },
    setDebtDataToCancel(debt) {
      if (debt.debtMoneyStatistics.paidMoney == 0) {
        this.$emit("setDebtData", debt);
        this.$bvModal.show("CancelDebtInstallments");
      } else {
        this.showMsg(this.$t("Installments.cancelNotvalid"));
      }
    },
    checkPrivilege,
    isDataExist,
    fullPathFileFromServer,
    hasInstallmentScheduleTime,
    hasDebtCancelInstallment,
  },
};
</script>
